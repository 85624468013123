:root {
    /* Core Colors */
    /* --primaryColor: #030E1C; */
    --primaryColor: white;
    --primaryText: black;
    --primaryBackground: #1C6D37;
    --secondaryColor: #02071C;
    --primaryDivisions: #334154;
    --primaryButton: #1C4ED8;
    --secondaryButton: #232d3d;
    --primaryField: #1E293B;
    --secondaryField: red;
  }

html, body {
    margin: 0;
    padding: 0;
}

.inner-text-title-up {
    font-size: 3vh;
    font-family: "Pixelify Sans", sans-serif;
    font-style: normal;
    text-shadow: -1px -1px 0 #00000010, 1px -1px 0 #76bf7620, -1px 1px 0 #00000077;
    color: var(--primaryText);
}


.inner-text-title-down {
    font-size: 3vh;
    font-family: "Pixelify Sans", sans-serif;
    font-style: normal;
    text-shadow: -1px -1px 0 #00000010, 1px -1px 0 #76bf7620, -1px 1px 0 #00000077;
    text-align: center;
    color: var(--primaryText);
}

.MCLanding {
    background-color: var(--primaryBackground);
    color: var(--primaryText);
}
.HeroWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    opacity: 1;

    /* Hero Image */
    background-image: url('../../../public/images/hero1.png');
    background-size: cover; /* Cover the entire div */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    
}

.HeroBox {
    background-color: #e6e4f3e7;
    border-radius: 12px;
    padding: 0.5rem;
}

.HeroTop {
    display: flex;
    align-items: center;
}
.HeroBoxLogo {
    background-color: #000000a9;
    border-radius: 12px;
    padding: 1rem;

}

.HeroBoxLogo h2 {
    font-size: inherit; /* Ensures h2 inherits font size from .HeroBoxLogo */
    font-weight: inherit; /* Ensures h2 inherits font weight from .HeroBoxLogo */
    color: inherit; /* Ensures h2 inherits color from .HeroBoxLogo */
    margin: 0; /* Removes default margin for h2 */
    padding: 0; /* Removes default padding for h2 */
    text-transform: none; /* No text transformation for h2 */
    line-height: normal; /* Default line height for h2 */
    letter-spacing: normal; /* Default letter spacing for h2 */
}

.ServiceSec h1 {
    font-size: inherit; /* Ensures h1 inherits font size from .HeroBoxLogo */
    font-weight: inherit; /* Ensures h1 inherits font weight from .HeroBoxLogo */
    color: inherit; /* Ensures h1 inherits color from .HeroBoxLogo */
    margin: 0; /* Removes default margin for h1 */
    padding: 0; /* Removes default padding for h1 */
    text-transform: none; /* No text transformation for h1 */
    line-height: normal; /* Default line height for h1 */
    letter-spacing: normal; /* Default letter spacing for h1 */
}
.HeroTopRight {
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: flex-end; /* Aligns content to the right */
    flex: 1;
    padding-right: 5%;
    height: 15vh;
}

.HeroTopRightLogoText {
    font-size: 3vh;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
}
.HeroTopLogo {
    max-width: 100%;
    max-height: 100%;
}

.HeroBoxLeft {
    /* box-shadow: #0AE44D -5px 5px, #09a538 -10px 10px, #055d20 -15px 15px */
    box-shadow: #919191 -5px 5px, #5b5a5a -10px 10px, #000000 -15px 15px
}

.HeroBoxRight {
    /* box-shadow: #0AE44D 5px 5px, #09a538 10px 10px, #055d20 15px 15px */
    box-shadow: #919191 5px 5px, #5b5a5a 10px 10px, #000000 15px 15px
}

.HeroUpLeft {
    flex: 1;
    margin-right: 40%;
    margin-left: 5%;
    margin-top: 5vh;
}
.HeroUp {
    display: flex;
    margin-top: 5vh;
    /* border: 1px solid #000; */
}

.HeroDownRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HeroDown {
    display: flex;
    margin-left: 30%;
    margin-right: 5%;
    margin-top: 15vh;
    flex-direction: column-reverse;
    padding-top: 0vh;
    /* border: 1px solid #000; */
}

.HeroAssetImage {
    width: 55%;
    height: 100%;
    border-radius: 10px
}

.HeroMiddleRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HeroMiddle {
    display: flex;
    margin-left: 60%;
    margin-right: 5%;
    margin-top: 5vh;
    flex-direction: column-reverse;
    padding-top: 0vh;
    /* border: 1px solid #000; */
}

.ServiceSec {
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 5%;
}

.ServiceSec h1 {
    font-size: 7vh;
}
.ServiceSecTextTittle {
    font-size: 5vh;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    margin-top: -2%;
}

.ServiceSecText {
    font-size: 3vh;
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
    font-weight: bold;
    text-align: center;
}

.inner-text-title-sec {
    font-size: 3vh;
    font-family: "Pixelify Sans", sans-serif;
    font-style: normal;
    text-shadow: -1px -1px 0 #00000010, 1px -1px 0 #76bf7620, -1px 1px 0 #00000077;
    text-align: center;
    color: var(--primaryText);
}

.Sec1Wrapper, .Sec2Wrapper, .Sec3Wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    opacity: 1;
}

.SecUpLeft {
    flex: 1;
    margin-right: 40%;
    margin-left: 5%;
    margin-top: 5vh;
}
.SecUp {
    display: flex;
    /* border: 1px solid #000; */
}

.SecDownRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SecDown {
    display: flex;
    margin-left: 30%;
    margin-right: 5%;
    margin-top: 5vh;
    flex-direction: column-reverse;
    padding-top: 0vh;
    /* border: 1px solid #000; */
}


.Footer {
    background-color: black; /* Dark background for the footer */
    color: #fff; /* White text color */
    text-align: center; /* Center the content */
    padding: 20px 0; /* Padding around the content */
    height: 10vh;
}

.FooterContent {
    display: flex;
    justify-content: center; /* Center the links horizontally */
    flex-direction: column;
    gap: 20px; /* Space between the links */
}

.FooterContent li {
    list-style-type: none; /* Remove bullet points */
    font-family: "Pixelify Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.FooterLink, .FooterEmail {
    color: #fff; /* White text color for the links */
    text-decoration: none; /* Remove underline from links */
    font-size: 16px; /* Font size for the links */
}

.FooterLink:hover, .FooterEmail:hover {
    text-decoration: underline; /* Add underline on hover for better UX */
}

@media (min-width: 1024px) {
    .inner-text-title-up {
        font-size: 4vh;
        margin-right: 45%;
    }
    
    
    .inner-text-title-down {
        font-size: 4vh;
    }
}

@media (min-width: 1400px) {
    .inner-text-title-up {
        font-size: 5vh;
        margin-right: 35%;
    }
    
    
    .inner-text-title-down {
        font-size: 5vh;
    }

    .SecUp {
        margin-left: 5%;
        margin-right: 35%;
    }
    .SecDown {
        margin-left: 40%;
        margin-right: 10%;
    }
}
